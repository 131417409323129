<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddVehicleSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-vehicle-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("Add vehicle") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer ref="infoVehicle">
        <b-form class="p-2" @submit.prevent="addVehicle">
          <b-form-group label-for="name" :label="$t('Name')">
            <validation-provider
              #default="{ errors }"
              :name="$t('Name')"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="name"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('Name')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label-for="registration_number"
            :label="$t('Registration number')"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Registration number')"
              rules="required"
            >
              <b-form-input
                id="registration_number"
                v-model="registrationNumber"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('Registration number')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <validation-provider
            rules="required"
            #default="{ errors }"
            :name="$t('Delivery man')"
          >
            <b-form-group
              :label="$t('Delivery man')"
              label-for="users"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="users"
                v-model="selectedUser"
                :options="users"
                label="name"
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-button
            variant="primary"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            >{{ $t("Add") }}</b-button
          >
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";
import { mounted } from "vue-echarts";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddVehicleSidebarActive",
    event: "update:is-add-vehicle-sidebar-active",
  },
  props: {
    isAddVehicleSidebarActive: {
      type: Boolean,
      required: true,
    },
  },

  async mounted() {
    try {
      const res = await instance.get("/parameters/delivery-users/");
      this.users = res.data;
    } catch (err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Error"),
          text: err.message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  },
  data() {
    return {
      required,
      name: "",
      users: [],
      selectedUser: null,
      registrationNumber: null,
    };
  },
  methods: {
    async addVehicle() {
      const success = await this.$refs.infoVehicle.validate();
      if (!success) return;

      try {
        await instance.post("/parameters/vehicles/", {
          name: this.name,
          registration_number: this.registrationNumber,
          delivery_man_id: this.selectedUser.id,
        });

        this.$emit("refetch-data");
        this.$emit("update:is-add-vehicle-sidebar-active", false);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Success"),
            text: this.$t("It has been created successfully"),
            icon: "CheckIcon",
            variant: "success",
          },
        });
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
